import { createContext, useContext, useState, FC } from "react";
import { ModalContextValue } from "./types";

const ModalPopupContext = createContext<ModalContextValue>({
  setShowModal: () => {},
  showModal: false,
});

export function useModalPopupContext() {
  return useContext(ModalPopupContext);
}

export const ModalPopupProvider: FC = ({ children }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <ModalPopupContext.Provider
      value={{
        setShowModal,
        showModal,
      }}
    >
      {children}
    </ModalPopupContext.Provider>
  );
};
